
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































































































































































































































.ctas {
  border-top: 1px solid $c-light-grey;

  @include mq(m) {
    display: flex;
  }
}

[class*='cta--'] {
  @extend %text-center;

  display: block;
  width: 100%;
  padding-right: col(1, 12);
  padding-left: col(1, 12);
  text-decoration: none;
  background: none;
  border: 0;
  cursor: pointer;

  & + & {
    border-top: 1px solid $c-light-grey;
  }

  [class*='--simple'] {
    @extend %fw-medium;
  }

  @include mq(m) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: auto;

    &:only-child {
      padding-bottom: 10rem;
    }

    & + & {
      border-top: 0;
      border-left: 1px solid $c-light-grey;
    }
  }

  @include mq($from: m, $until: xl) {
    padding-right: col(0.5, 12);
    padding-left: col(0.5, 12);
  }
}

.cta__duo-title {
  @include mq(xxl) {
    font-size: 3.5rem;
  }
}

.cta__title {
  color: $c-mustard;
}

.cta__title__headline {
  display: block;
  color: $c-mustard;
}

.cta__text,
[class*='cta__text--'] {
  color: $c-dark-grey;

  &[class*='--bold'] {
    @extend %fw-bold;
  }
}

.cta__icon {
  margin: 0 auto 3.5rem;
  color: $c-mustard;
}

.cta__arrow {
  color: $c-mustard;
  transition: transform 0.5s $ease-softer;

  [class*='cta--']:hover &,
  [class*='cta--']:focus-visible & {
    transform: translateX(10px);
  }
}

// DUO PICTURE

.duo-picture {
  @extend %border-radius-small;

  padding: $spacing;
  box-shadow: $card-shadow;

  @include mq(m) {
    display: flex;
    align-items: center;
  }
}

.duo-picture__picture {
  @extend %border-radius-small;

  position: relative;

  @include mq(m) {
    position: relative;
    flex-shrink: 0;
    width: col(4, 10);
  }

  @include mq(l) {
    width: col(4.5, 10);
  }

  @include mq(xl) {
    width: col(4, 10);
  }
}

.duo-picture__content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: $spacing;

  @include mq(m) {
    margin-top: 0;
    padding: 0 $spacing * 2;
  }

  @include mq(xl) {
    margin-left: $spacing;
    padding: $spacing * 2;
  }
}
